import { useEffect, useState } from 'react';

export enum Key {
  Backspace = 'Backspace',
  Tab = 'Tab',
  Shift = 'Shift',
  Control = 'Control',
  Alt = 'Alt',
  Escape = 'Escape',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Space = 'Space',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4'
}

export function useKeyDown() {
  const [downKeys, setDownKeys] = useState([]);

  function isKeyDown(key: Key) {
    if (key === Key.Space) {
      return downKeys.includes(key) || downKeys.includes(' ');
    }

    return downKeys.includes(key);
  }

  function downHandler({ key }) {
    setDownKeys([...downKeys, key]);
  }

  function upHandler({ key }) {
    setDownKeys(downKeys.filter((k) => key !== k));
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return {
    isKeyDown,
    downKeys
  };
}
