import { DeckMetadata } from '../types/database/DeckMetadata';

export const getSortedDecks = (decks: { [id: string]: DeckMetadata }) => {
  const keys = Object.keys(decks);

  const decksArray = keys.map(
    (id) =>
      ({
        id,
        ...decks[id]
      } as DeckMetadata)
  );

  const sortedDecks = (decksArray as DeckMetadata[]).sort(
    (a, b) => b.created.seconds - a.created.seconds
  );

  return sortedDecks;
};

export const getUploadId = (userId: string, deckId: string) => {
  return userId + '_' + deckId;
};

export const getUploadKey = (
  userId: string,
  deckId: string,
  fileType: string
) => {
  return getUploadId(userId, deckId) + '.' + fileType;
};
