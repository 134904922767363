import React from 'react';
import router from 'next/router';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import UploadButton from '@/components/common/upload-button/UploadButton';
import DecksList from './decks-list/DecksList';
import { DeckStatus } from '@/app/constants/DeckStatus';
import { getSortedDecks } from '@/app/utils/deckUtils';
import Link from 'next/link';
import WikipediaSearch from '@/components/common/wikipedia-search/WikipediaSearch';
import PageLayout from '../common/page-layout/PageLayout';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import AlternateOptions from '../common/alternate-options/AlternateOptions';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';

const AppHome = () => {
  const { createDeck, uploadingDeck, decks, sharedDecks, decksLoaded } =
    useDeck();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const { selectedFolder, foldersEnabled } = useSettings();

  const { isProEligible, subscription } = useCustomer();

  const onAlernateOptionsChange = (
    deckName: string,
    file: any,
    videoId?: string
  ) => {
    if (videoId) {
      trackEvent(analyticsInstance, 'generate_upload', {
        upload_source: 'youtube',
        file_type: 'txt'
      });
    } else {
      trackEvent(analyticsInstance, 'generate_upload', {
        upload_source: 'wikipedia',
        file_type: 'txt'
      });
    }

    createDeck(
      deckName,
      file,
      videoId,
      foldersEnabled ? selectedFolder : undefined
    );
  };

  const onChange = (deckName: string, file: any, videoId?: string) => {
    const fileType = file.name.split('.').slice(-1)[0].toLowerCase();

    trackEvent(analyticsInstance, 'generate_upload', {
      upload_source: 'file',
      file_type: fileType
    });

    createDeck(
      deckName,
      file,
      videoId,
      foldersEnabled ? selectedFolder : undefined
    );
  };

  const sortedDecks = getSortedDecks(decks);
  const sortedSharedDecks = getSortedDecks(sharedDecks);

  return (
    <PageLayout authenticated>
      <div className="flex flex-col">
        <div className="flex flex-col items-center pt-20 lg:flex-row lg:items-start lg:justify-center">
          <div className="mt-6 w-4/5 md:w-3/5 lg:w-2/5">
            <UploadButton disabled={!!uploadingDeck} onChange={onChange} />
          </div>
          <div className="flex w-full items-center justify-center lg:ml-6 lg:mr-3 lg:mt-7 lg:w-auto">
            <div className="w-full flex-1 border-t border-gray-600 lg:hidden lg:w-0"></div>
            <div className="p-2 text-secondary-content lg:p-0">or</div>
            <div className="w-full flex-1 border-t border-gray-600 lg:hidden lg:w-0"></div>
          </div>
          <div className="mt-1 w-4/5 md:mt-1 md:w-3/5 lg:ml-4 lg:mt-6 lg:w-80">
            <AlternateOptions
              disabled={!!uploadingDeck}
              onChange={onAlernateOptionsChange}
              isPreview={false}
            />
          </div>
        </div>
      </div>
      {sortedDecks.length === 1 &&
        sortedDecks[0].status === DeckStatus.Complete && (
          <div className="flex flex-col items-center">
            <p className="ml-4 mr-4 mt-4 max-w-md text-center text-sm text-base-content">
              <span className="font-bold">Click on your deck</span> to start
              studying or download your flashcards. Downloaded flashcards can be
              imported into <span className="font-bold">Quizlet</span>,{' '}
              <span className="font-bold">Anki</span>,{' '}
              <span className="font-bold">Cram</span>,{' '}
              <span className="font-bold">Tinycards</span> or your preferred
              flashcard tool
            </p>
          </div>
        )}
      <DecksList onSelect={(deck) => router.push(`/app/${deck.id}`)} />

      {decksLoaded &&
        decks &&
        sortedSharedDecks.length === 0 &&
        sortedDecks.length === 0 && (
          <div className="flex flex-1 flex-col items-center justify-center p-6">
            <p className="pt-4 text-center text-lg font-bold">
              Welcome to Limbiks!
            </p>
            <p className="pt-4 text-center">
              Click the button above to a select a file and generate your first
              deck!
            </p>
            <p className="max-w-sm pl-2 pr-2 pt-4 text-center">
              Not sure where to start? Try uplading one of the files from the{' '}
              <span
                onClick={() => {
                  trackEvent(
                    analyticsInstance,
                    'generate_new_user_click_examples'
                  );
                }}
                className="font-bold text-indigo-400"
              >
                <Link href="/examples">Examples</Link>
              </span>{' '}
              page!
            </p>
            {!subscription.isPro && (
              <p className="max-w-sm pt-4 text-center">
                As a free user you are limited to 10 uploads per month.{' '}
                {isProEligible
                  ? 'Upgrade to Limbiks Pro for 100 uploads per month.'
                  : ''}
              </p>
            )}
          </div>
        )}
    </PageLayout>
  );
};

export default AppHome;
