import { DeckMetadata } from '@/app/types/database/DeckMetadata';
import { FolderMetadata } from '@/app/types/database/FolderMetadata';

export const getFolderCounts = (
  folders: FolderMetadata[],
  decks: { [id: string]: DeckMetadata }
) => {
  const countDictionary = {};

  const decksArray = Object.values(decks);

  folders.forEach((folder) => {
    // Use == instead of === because we are saying the undefined folderId should go in the null 'My Decks' Folder
    countDictionary[folder.id] = decksArray.filter(
      (deck) => deck.folderId == folder.id
    ).length;
  });

  return countDictionary;
};
