import { useDeck } from '@/app/context/DeckContext/useDeck';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import AddIcon from '@/app/icons/AddIcon';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { getFolderCounts } from './utils';
import { reorder } from '@/app/utils/arrayUtils';
import { useModal } from '@/app/context/ModalContext/useModal';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';

const FOLDERS_MENU_ID = 'folders-menu-id';

export const FoldersList = () => {
  const [contextedFolderId, setContextedFolderId] = useState('');

  const { folders, selectedFolder, selectFolder, updateFolders } =
    useSettings();

  const { openModal } = useModal();

  const { deleteFolder } = useSettings();
  const { decks, deleteDeck } = useDeck();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const folderCounts = getFolderCounts(folders, decks);

  useEffect(() => {
    setTimeout(() => {
      let testDiv = document.getElementById(
        'folder-' + selectedFolder ?? 'null'
      );

      if (testDiv) {
        testDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }, [selectedFolder]);

  const { show } = useContextMenu({
    id: FOLDERS_MENU_ID
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'rgba(100,100,100,.5)' : 'unset',
    ...draggableStyle
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    trackEvent(analyticsInstance, 'generate_folders_drag');

    const reorderedFolders = reorder(
      folders,
      result.source.index,
      result.destination.index
    );

    updateFolders(reorderedFolders);
  }

  const onDeleteFolder = async (folderId: string) => {
    const folder = folders.find((folder) => folder.id == folderId);

    const decksToDelete = Object.entries(decks)
      .filter(([_, metadata]) => metadata.folderId == folderId)
      .map(([id, _]) => id);

    if (decksToDelete.length > 0) {
      var confirmed = window.confirm(
        `Are you sure you want to delete the folder "${folder.name}"?\n\n\nAll ${decksToDelete.length} decks inside this folder will be deleted.`
      );

      if (!confirmed) {
        return;
      }
    }

    for (var deckId of decksToDelete) {
      await deleteDeck(deckId, true);
    }

    await deleteFolder(folderId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            className="min-h-16 mt-6 flex min-w-full gap-6 overflow-x-auto p-3"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div
              onClick={() => {
                trackEvent(analyticsInstance, 'generate_folders_click_create');
                openModal('createFolder');
              }}
              data-tip="New Folder"
              className="tooltip tooltip-right bordered flex cursor-pointer items-center justify-center gap-2 rounded border-solid border-primary p-2 hover:bg-neutral"
            >
              <AddIcon className="fill-base-content" />
            </div>
            {folders.map((folder, index) => (
              <Draggable
                key={folder.id}
                draggableId={folder.id ?? 'null'}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    id={'folder-' + folder.id ?? 'null'}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      trackEvent(
                        analyticsInstance,
                        'generate_folders_conext_menu'
                      );
                      setContextedFolderId(folder.id);
                      show({
                        event: e
                      });
                    }}
                    ref={provided.innerRef}
                    onClick={() => {
                      trackEvent(analyticsInstance, 'generate_folders_click');
                      let testDiv = document.getElementById(
                        'folder-' + folder.id ?? 'null'
                      );
                      testDiv.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                      });
                      selectFolder(folder.id);
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={folder.id}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className={`bordered flex-shrink-0 p-2 text-base-content ${
                      selectedFolder === folder.id
                        ? 'border-b-2 border-primary'
                        : ''
                    }`}
                  >
                    {folder.name} ({folderCounts[folder.id]})
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Menu id={FOLDERS_MENU_ID}>
        <Item
          onClick={() => {
            trackEvent(analyticsInstance, 'generate_folders_click_rename');
            openModal('renameFolder', contextedFolderId);
          }}
        >
          Rename
        </Item>
        <Item
          onClick={() => {
            trackEvent(analyticsInstance, 'generate_folders_click_delete');
            onDeleteFolder(contextedFolderId);
          }}
        >
          Delete
        </Item>
      </Menu>
    </DragDropContext>
  );
};
