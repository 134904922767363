import DeckAPI from '@/app/api/DeckAPI/DeckAPI';
import { DeckStatus } from '@/app/constants/DeckStatus';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import { useFeedback } from '@/app/context/FeedbackContext/useFeedback';
import { useSettings } from '@/app/context/SettingsContext/useSettings';
import { Key, useKeyDown } from '@/app/hooks/useKeyDown';
import { useOutsideAlerter } from '@/app/hooks/useOutsideAlerter';
import { DeckMetadata } from '@/app/types/database/DeckMetadata';
import { getSortedDecks } from '@/app/utils/deckUtils';
import { getTimeAgo } from '@/app/utils/timeUtils';
import Loader from '@/components/common/loader/Loader';
import Progress from '@/components/common/progress/Progress';
import React, { useEffect, useRef, useState } from 'react';

import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu
} from 'react-contexify';
import { useRouter } from 'next/router';
import { FoldersList } from './folders-list/FoldersList';
import { useModal } from '@/app/context/ModalContext/useModal';
import { v4 as uuidv4 } from 'uuid';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import FlipMove from 'react-flip-move';

const MENU_ID = 'menu-id';

export default function DecksList({ onSelect }) {
  const {
    decks,
    sharedDecks,
    decksLoaded,
    uploadProgress,
    uploadingDeck,
    deleteDeck,
    mergeDecks
  } = useDeck();
  const { user, verifyEmail } = useAuth();
  const { submitFeedback } = useFeedback();

  const { isPro } = useCustomer();

  const { foldersEnabled, selectedFolder } = useSettings();

  const { openModal } = useModal();

  const [contextedDeckId, setContextedDeckId] = useState('');

  const router = useRouter();

  const { show } = useContextMenu({
    id: MENU_ID
  });

  const contextedDeck = decks[contextedDeckId];

  const { trackEvent, analyticsInstance } = useAnalytics();
  const { isKeyDown } = useKeyDown();

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => {
    setHighlighedDecks([]);
  });

  const [shouldRender, setShouldRender] = useState(true);
  const [renderGuid, setRenderGuid] = useState(uuidv4());

  useEffect(() => {
    setShouldRender(true);
  }, [renderGuid]);

  useEffect(() => {
    setShouldRender(false);
    setRenderGuid(uuidv4());
  }, [selectedFolder, foldersEnabled]);

  const [highlightedDecks, setHighlighedDecks] = useState<string[]>([]);

  const sortedDecks = foldersEnabled
    ? getSortedDecks(decks).filter((deck) => deck.folderId == selectedFolder)
    : getSortedDecks(decks);

  const sortedSharedDecks = getSortedDecks(sharedDecks);

  const onVerify = async () => {
    try {
      trackEvent(analyticsInstance, 'generate_shared_deck_click_verify_email');

      await verifyEmail();

      window.alert(
        'Email verification sent successfully.\n\nAfter verifying your email, refresh the page.'
      );
    } catch (e) {
      submitFeedback('Email verification failed: ' + e.toString());
      window.alert('There was an issue sending a verification email.');

      trackEvent(analyticsInstance, 'generate_shared_deck_verify_failed');
    }
  };

  const onAccept = async (deckId: string) => {
    try {
      trackEvent(analyticsInstance, 'generate_shared_deck_click_accept', {
        deck_id: deckId
      });

      await DeckAPI.acceptShare(
        deckId,
        foldersEnabled ? selectedFolder : undefined
      );
      submitFeedback('Shared Deck Accepted: ' + user.email);
    } catch (e) {
      submitFeedback('Accept Shared Deck failed: ' + e.toString());
      window.alert('There was an issue accepting this shared deck.');
      trackEvent(analyticsInstance, 'generate_shared_deck_accept_failed', {
        error: e.toString(),
        deck_id: deckId
      });
    }
  };

  const onReject = async (deckId: string) => {
    try {
      trackEvent(analyticsInstance, 'generate_shared_deck_click_reject', {
        deck_id: deckId
      });

      await DeckAPI.rejectShare(deckId);
      submitFeedback('Shared Deck Rejected: ' + user.email);
    } catch (e) {
      submitFeedback('Reject Shared Deck failed: ' + e.toString());

      trackEvent(analyticsInstance, 'generate_shared_deck_reject_failed', {
        error: e.toString(),
        deck_id: deckId
      });
    }
  };

  const onClick =
    (deck: DeckMetadata) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      if (uploadingDeck) {
        return;
      }

      if (isKeyDown(Key.Control)) {
        if (deck.status === DeckStatus.Complete) {
          if (highlightedDecks.includes(deck.id)) {
            setHighlighedDecks(highlightedDecks.filter((d) => d !== deck.id));
          } else {
            setHighlighedDecks([...highlightedDecks, deck.id]);
          }
        }

        return;
      }

      onSelect(deck);
    };

  return (
    <div>
      <FoldersList />
      {decksLoaded &&
        decks &&
        (sortedDecks.length > 0 || sortedSharedDecks.length > 0) &&
        shouldRender && (
          <div className="mt-3 w-full p-4">
            <div
              ref={wrapperRef}
              className="relative grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
            >
              <FlipMove typeName={null}>
                {sortedSharedDecks.map((deck) => (
                  <div
                    key={deck.id + '_shared'}
                    onContextMenu={show as any}
                    className={`card border-b-4 border-solid border-transparent bg-base-300 text-primary-content shadow-lg lg:card-side`}
                  >
                    <div className="card-body flex">
                      <p className="break-all text-lg text-base-content">
                        {deck.name}
                      </p>
                      <p className="mt-1 text-sm font-semibold text-secondary-content">
                        {deck.cardCount} Flashcards
                      </p>
                      <p className="flex-1 text-xs text-secondary-content">
                        Shared by {deck.sharedBy}
                      </p>
                      {!user?.emailVerified && (
                        <>
                          <div className="mt-4 flex items-center gap-2 text-sm font-semibold text-secondary-content">
                            <div onClick={onVerify} className="btn btn-sm">
                              Verify Your Email
                            </div>
                          </div>
                        </>
                      )}
                      {user?.emailVerified && (
                        <div className="mt-4 flex items-center gap-2 text-sm font-semibold text-secondary-content">
                          <div
                            onClick={() => onReject(deck.id)}
                            className="btn btn-sm"
                          >
                            Reject
                          </div>
                          <div
                            onClick={() => onAccept(deck.id)}
                            className="btn btn-primary btn-sm"
                          >
                            Accept
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {sortedDecks.map((deck) => (
                  <div
                    key={deck.id}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setContextedDeckId(deck.id);
                      trackEvent(
                        analyticsInstance,
                        'generate_deck_context_menu',
                        {
                          deck_id: deck.id
                        }
                      );
                      show({
                        event: e
                      });
                    }}
                    onClick={(e) => {
                      trackEvent(analyticsInstance, 'generate_select_deck', {
                        deck_id: deck.id,
                        status: deck.status,
                        card_count: deck.cardCount,
                        is_image_occlusion: deck.isImageOcclusion ? 1 : 0
                      });

                      onClick(deck)(e);
                    }}
                    className={`card border-b-4 border-solid ${
                      highlightedDecks.includes(deck.id)
                        ? highlightedDecks[0] === deck.id
                          ? 'border-4 border-primary'
                          : 'border-gray border-4'
                        : 'border-transparent'
                    } ${
                      !uploadingDeck
                        ? highlightedDecks.includes(deck.id)
                          ? 'cursor-pointer'
                          : 'cursor-pointer hover:border-primary'
                        : deck.id === uploadingDeck
                        ? ''
                        : 'opacity-60'
                    } bg-base-300 text-primary-content shadow-lg lg:card-side`}
                  >
                    <div className="card-body flex">
                      <p className="break-all text-lg text-base-content">
                        {deck.name}
                      </p>
                      {uploadingDeck !== deck.id &&
                        (deck.status === DeckStatus.PendingUpload ||
                          deck.status === DeckStatus.Failed) && (
                          <>
                            <p className="mt-1 text-sm font-semibold text-secondary-content">
                              {deck.status === DeckStatus.PendingUpload
                                ? '...'
                                : 'Deck Failed'}
                              {deck.errorMessage
                                ? ' - ' + deck.errorMessage
                                : ''}
                            </p>
                            <p className="mt-1 flex-1 text-xs text-secondary-content">
                              Try again or delete this deck
                            </p>
                          </>
                        )}
                      {uploadingDeck === deck.id && (
                        <div className="mt-1 text-sm font-semibold text-secondary-content">
                          Uploading <Progress percent={uploadProgress} />
                        </div>
                      )}
                      {deck.status === DeckStatus.Generating && (
                        <div className="mt-2 flex items-center text-sm font-semibold text-secondary-content">
                          <Loader />
                          <div className="ml-2">
                            <div>
                              {deck.isImageOcclusion
                                ? 'Generating Occlusions'
                                : 'Generating Flashcards'}
                            </div>
                            <div className="mt-1 text-xs">~2 Minutes</div>
                          </div>
                        </div>
                      )}
                      {deck.status === DeckStatus.Preprocessing && (
                        <div className="mt-2 flex items-center text-sm font-semibold text-secondary-content">
                          <Loader />
                          <div className="ml-2">
                            <div>Getting File Ready</div>
                            <div className="mt-1 text-xs">~10 seconds</div>
                          </div>
                        </div>
                      )}
                      {deck.status === DeckStatus.Ready && (
                        <div className="mt-2 flex items-center text-sm font-semibold text-secondary-content">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="mr-2"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                fill="#BBBBBB"
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                              />
                            </svg>
                          </div>
                          <div className="ml-2">
                            <div>Ready to generate flashcards</div>
                            <div className="mt-1 text-xs">Click here</div>
                          </div>
                        </div>
                      )}
                      {deck.status === DeckStatus.Complete && (
                        <>
                          <p className="mt-1 text-sm font-semibold text-secondary-content">
                            {deck?.cardCount}{' '}
                            {deck.isImageOcclusion
                              ? 'Occlusions'
                              : 'Flashcards'}
                          </p>
                          <p className="mt-1 flex-1 text-xs text-secondary-content">
                            {getTimeAgo(deck.created.toDate())}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </FlipMove>
            </div>
            <Menu id={MENU_ID}>
              <Item
                onClick={() => {
                  trackEvent(
                    analyticsInstance,
                    'generate_deck_context_menu_rename',
                    {
                      deck_id: contextedDeckId
                    }
                  );
                  router.push('/app/' + contextedDeckId + '?rename=true');
                }}
              >
                Rename
              </Item>
              {contextedDeck?.cardCount > 0 && (
                <>
                  {!contextedDeck.sharedById && (
                    <Item
                      onClick={() => {
                        trackEvent(
                          analyticsInstance,
                          'generate_deck_context_menu_share',
                          {
                            deck_id: contextedDeckId
                          }
                        );
                        router.push('/app/' + contextedDeckId + '/share');
                      }}
                    >
                      Share
                    </Item>
                  )}
                  <Item
                    onClick={() => {
                      trackEvent(
                        analyticsInstance,
                        'generate_deck_context_menu_merge',
                        {
                          deck_id: contextedDeckId
                        }
                      );
                      openModal('merge', contextedDeckId);
                    }}
                  >
                    Merge
                  </Item>
                </>
              )}

              <Item
                onClick={() => {
                  trackEvent(
                    analyticsInstance,
                    'generate_deck_context_menu_change_folder',
                    {
                      deck_id: contextedDeckId
                    }
                  );

                  !foldersEnabled
                    ? openModal('settings')
                    : openModal('changeFolder', contextedDeckId);
                }}
              >
                Change Folder
              </Item>
              <Separator />
              <Item
                onClick={() => {
                  trackEvent(
                    analyticsInstance,
                    'generate_deck_context_menu_delete',
                    {
                      deck_id: contextedDeckId
                    }
                  );
                  deleteDeck(contextedDeckId);
                }}
              >
                Delete
              </Item>
            </Menu>
          </div>
        )}
    </div>
  );
}
